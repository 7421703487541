export const CURRENT_VIEW = 'CURRENT_VIEW',
  CURRENT_LIST_VIEW = 'CURRENT_LIST_VIEW',
  ADD_TYPE = 'ADD_TYPE',
  ADD_METHOD = 'ADD_METHOD',
  UPDATE_FOLDERS = 'UPDATE_FOLDERS',
  SELECT_FOLDER = 'SELECT_FOLDER',
  ADD_LIST = 'ADD_LIST',
  REMOVE_LIST = 'REMOVE_LIST',
  RESET_LIST = 'RESET_LIST',
  CHANGE_TEMPLATE = 'CHANGE_TEMPLATE',
  TOGGLE_CANOPY_MODAL = 'TOGGLE_CANOPY_MODAL',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  SET_FILTER_COUNT = 'SET_FILTER_COUNT',
  SET_LANGUAGE = 'SET_LANGUAGE',
  UPDATE_LIST_DATA = 'UPDATE_LIST_DATA',
  UPDATE_SPATIAL_DATA = 'UPDATE_SPATIAL_DATA',
  UPDATE_FILTERED_DATA = 'UPDATE_FILTERED_DATA',
  UPDATE_LIST_LEVEL_ANALYSIS = 'UPDATE_LIST_LEVEL_ANALYSIS',
  UPDATE_SELECTED_LOCATIONS = 'UPDATE_SELECTED_LOCATIONS',
  UPDATE_SELECTED_AND_VISIBLE_LOCATIONS = 'UPDATE_SELECTED_AND_VISIBLE_LOCATIONS',
  UPDATE_INFO_BUBBLE_CACHE = 'UPDATE_INFO_BUBBLE_CACHE',
  TOGGLE_POPOVER = 'TOGGLE_POPOVER',
  UPDATE_LOADING_ICON_ID = 'UPDATE_LOADING_ICON_ID',
  UPDATE_BUBBLE_DATA = 'UPDATE_BUBBLE_DATA',
  UPDATE_POPOVER_ANCHOR = 'UPDATE_POPOVER_ANCHOR',
  UPDATE_DIAGNOSTIC_VIEW = 'UPDATE_DIAGNOSTIC_VIEW',
  UPDATE_SELECTED_LIST = 'UPDATE_SELECTED_LIST';
